<template>
    <TableOfContentsView
        v-editable="blok"
        :data="tableOfContentsData"
        class="py-20"
    />
</template>

<script setup lang="ts">
import { slugify } from '~/utils/helpers';
import TableOfContentsView from '~/components/page-building/table-of-contents-view/TableOfContentsView.vue';
import TableOfContentsType from '~/types/TableOfContentsType';
import TableOfContentsSectionType from '~/types/TableOfContentsSectionType';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const tableOfContentsData = computed<TableOfContentsType>(() => {
    const sections: TableOfContentsSectionType[] = [];
    Object.values(props.blok.sections).forEach((section: any) => {
        sections.push({
            id: slugify(section.heading),
            heading: section.heading,
            subheading: section.subheading,
            content: richTextToHtml(section.content) as string,
            vEditable: section,
        } as TableOfContentsSectionType);
    });

    return {
        tocHeading: props.blok.heading,
        sections,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
    } as TableOfContentsType;
});
</script>
